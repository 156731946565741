// i18next-extract-mark-ns-start privacy
import * as React from "react"
import { graphql } from "gatsby"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageSection from "../components/page-section"

const PrivacyPolicyPage = () => {

  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t("Polityka prywatności")} />
      <PageSection>
        <h1 className="pb-3"><Trans>Polityka prywatności</Trans></h1>
      </PageSection>
      <PageSection className="bg-light text-black">
        <h3><Trans>Polityka prywatności i wykorzystywania plików cookies</Trans></h3>
        <h5 className="my-5 fw-bold">§1. <Trans>Postanowienia Ogólne</Trans></h5>
        <p>1. <Trans>Administratorem danych jest Rosa Venti Sp. z o.o. z siedzibą w Warszawie, wpisanej do Rejestru Przedsiębiorców pod numerem KRS:  ‭0000832586‬, dla której akta rejestrowe prowadzi Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, NIP: 5252817648‬, REGON: 385728393‬. Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach</Trans>.</p>
        <p>2. <Trans>Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak jak zostało to opisane w Polityce Prywatności (jeżeli wynika to bezpośrednio z opisu)</Trans>.</p>
        <p>3. <Trans>Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został określeniem „Ty”,  „Administrator” – „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE</Trans>.</p>
        <p>4. <Trans>Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL)</Trans>.</p>
        <p>5. <Trans>Dane osobowe podawane w formularzach w serwisie wodnawarszawa.pl są traktowane jako poufne i nie są widoczne dla osób nieuprawnionych</Trans></p>
        <h5 className="my-5 fw-bold">§2. <Trans>Administrator Danych</Trans></h5>
        <p>1. <Trans>Usługodawca jest administratorem danych swoich klientów. Oznacza to, że jeśli jesteś lub byłeś naszym Klientem, to przetwarzamy Twoje dane jak: imię, nazwisko, adres e-mail, numer telefonu oraz adres IP</Trans>.</p>
        <p>2. <Trans>Usługodawca jest także administratorem osób zapisanych na newsletter i osób zapisanych na rejsy</Trans>.</p>
        <p>3. <Trans>Dane osobowe przetwarzane są</Trans>:</p>
        <div className="ms-5">
          <p>1. <Trans>zgodnie z przepisami dotyczącymi ochrony danych osobowych</Trans>,</p>
          <p>2. <Trans>zgodnie z wdrożoną Polityką Prywatności</Trans>,</p>
          <p>3. <Trans>w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji Usług świadczonych drogą elektroniczną</Trans>,</p>
          <p>4. <Trans>w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą</Trans>:</p>
          <div className="ms-5">
            <p>1. <Trans>w zakresie i celu zgodnym ze zgodą wyrażoną przez Ciebie jeśli zapisałeś się na newsletter</Trans>,</p>
            <p>2. <Trans>w zakresie i celu zgodnym z wyrażoną przez Ciebie zgodą jeżeli zapisałeś się na rejs</Trans>.</p>
          </div>
          <p>5. <Trans>Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego</Trans>.
          </p>
          <p>6. <Trans>Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji Usługodawcy jest możliwy drogą elektroniczną pod adresem e-mail: <a target="_blank" href="mailto:kontakt@wodnawarszawa.pl">kontakt@wodnawarszawa.pl</a></Trans>.
          </p>
          <p>7. <Trans>Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych</Trans>.</p>
          <p>8. <Trans>Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom ścigania)</Trans>.
          </p>
          <p>9. <Trans>Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych</Trans>.
          </p>
          <p>10. <Trans>Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa</Trans>.</p>
          <p>11. <Trans>Wdrożyliśmy pseudonimizację, szyfrowanie danych oraz mamy wprowadzoną kontrolę dostępu dzięki czemu minimalizujemy skutki ewentualnego naruszenia bezpieczeństwa danych</Trans>.</p>
          <p>12. <Trans>Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy</Trans>.</p>
        </div>
        <h5 className="my-5 fw-bold">§3. <Trans>Pliki cookies na stronach internetowych wodnawarszawa.pl</Trans></h5>
        <p>1. <Trans>Witryna wodnawarszawa.pl używa cookies. Są to niewielkie pliki tekstowe wysyłane przez serwer www i przechowywane przez oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik. Parametry pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z wcześniej odwiedzonych witryn. Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie i dacie, lokalizacji oraz informacji przesyłanych do witryny za pośrednictwem formularza kontaktowego</Trans>. </p>
        <p>2. <Trans>Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób użytkownicy korzystają z naszych witryn, aby usprawniać funkcjonowanie serwisu zapewniając bardziej efektywną i bezproblemową nawigację. Monitorowania informacji o użytkownikach dokonujemy korzystając z narzędzi analitycznych, które rejestrują zachowanie użytkownika na stronie.Cookies identyfikuje użytkownika, co pozwala na dopasowanie treści witryny, z której korzysta, do jego potrzeb. Zapamiętując jego preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego reklam. Stosujemy pliki cookies, aby zagwarantować najwyższy standard wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz firmy w celu optymalizacji działań</Trans>.
        </p>
        <p>3. <Trans>Na naszej witrynie wykorzystujemy następujące pliki cookies</Trans>:
        </p>
        <div className="ms-5">
          <p>1. <Trans>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach serwisu</Trans>;
          </p>
          <p>2. <Trans>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych serwisu</Trans>;</p>
          <p>3. <Trans>„reklamowe” pliki cookies, umożliwiające dostarczanie użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań</Trans>.
          </p>
        </div>
        <p>4. <Trans>Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce internetowej. Instrukcja zarządzania plikami cookies jest dostępna na stronie <a target="_blank" href="http://www.allaboutcookies.org/manage-cookies">allaboutcookies.org</a></Trans></p>
        <p>5. <Trans>Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w miejscach, w których użytkownik wypełniając formularz wyraźnie wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej usługi</Trans>.</p>
      </PageSection>
    </Layout >
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["privacy", "navigation","footbar"]}, language:{eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;